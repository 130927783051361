<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ sprintf($t('pages.ecommerce.shippingMethod.rate.titlePattern'), [shippingMethod.name ?? '']) }}
        </h2>

        <router-link to="/ecommerce/shipping-method" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.ecommerce.shippingMethod.title") }}
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.ecommerce.shippingMethod.rate.title')"
        :subTitle="$t('pages.ecommerce.shippingMethod.rate.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:type="{ row: record }">
            {{ record.type_id }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchRate(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_rate" ref="addRateModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="rateForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                    <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                                </span>
                                <div class="d-flex flex-stack flex-grow-1">
                                    <div class="fw-bold">
                                        <div class="fs-6 text-gray-600" v-html="$t('pages.ecommerce.shippingMethod.rate.warnings.rate')"></div>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.shippingMethod.rate.cols.type') }}</label>
                                <el-form-item prop="type_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.type_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(type, typeIndex) in types" :key="typeIndex" :value="type.id" :label="type.name">{{ type.name }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>-->

                            <div class="row">
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.shippingMethod.rate.cols.min') }}</label>
                                    <el-form-item prop="min" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.min" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                    </el-form-item>
                                </div>
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.shippingMethod.rate.cols.max') }}</label>
                                    <el-form-item prop="max" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.max" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.shippingMethod.rate.cols.amount') }}</label>
                                <el-form-item prop="amount" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.amount" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                </el-form-item>
                            </div>

                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "_id",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.shippingMethod.rate.cols.id"),
                    key: "id"
                },
              /*  {
                    name: this.$t("pages.ecommerce.shippingMethod.rate.cols.type"),
                    scopedSlots: {customRender: "type"},
                },*/
                {
                    name: this.$t("pages.ecommerce.shippingMethod.rate.cols.min"),
                    key: "min"
                },
                {
                    name: this.$t("pages.ecommerce.shippingMethod.rate.cols.max"),
                    key: "max"
                },
                {
                    name: this.$t("pages.ecommerce.shippingMethod.rate.cols.amount"),
                    key: "amount"
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
            shippingMethod: {},
            /*types: [
                {id: 1, name: 'TIP1'},
                {id: 2, name: 'TIP2'}
            ]*/
        }
    },
    computed: {
        shippingMethodID() {
            return this.$route.params.id;
        },
        table() {
            return this.$store.state.ecommerce.shippingMethod.rate.table;
        }
    },
    created() {
        if (!this.shippingMethodID || !(this.shippingMethodID > 0)) {
            this.$router.push({
                path: "/ecommerce/shipping-method"
            });
        }
    },
    mounted() {
        if (this.shippingMethodID && this.shippingMethodID > 0) {
            this.$store.dispatch('ecommerce/shippingMethod/rate/get', {page: {}, filterData: {method_id: this.shippingMethodID}});
            this.loadShippingMethod();
        }
    },
    methods: {
        loadShippingMethod() {
            this.table.loading = true;

            this.axios.get(this.endpoints['ecommerce_shipping_method'] + '/' + this.shippingMethodID).then((response) => {
                this.shippingMethod = response.data.data;
            });
        },
        newRate() {
            this.form.updateStatus = false;
            this.form.data = {
                type_id: 1,
                method_id: this.shippingMethodID
            };
            this.form.title = this.$t("pages.ecommerce.shippingMethod.rate.newRate");
            this.showModal(this.$refs.addRateModal);
        },
        fetchRate(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.shippingMethod.rate.editRate");
            this.axios.get(this.endpoints['ecommerce_shipping_method_rate'] + '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;
                this.showModal(this.$refs.addRateModal);
            });
        },
        refreshTable(){
            this.$store.dispatch("ecommerce/shippingMethod/rate/refresh");
        },
        onSubmit() {
            this.$refs.rateForm.validate((valid) => {
                if (valid) {
                    if(this.prepareRateFormData()) {
                        this.form.loading = true;

                        if (this.form.data.id) {
                            this.axios.put(this.endpoints['ecommerce_shipping_method_rate'] + '/' + this.form.data.id, this.form.data).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addRateModal);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                if(error.response.data.message == 'min_must_greater_than_max_other_records') {
                                    error.response.data.message = this.$t('pages.ecommerce.shippingMethod.rate.responseMessages.minGreaterMaxOtherRecords');
                                }

                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        } else {
                            this.axios.post(this.endpoints['ecommerce_shipping_method_rate'], this.form.data).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addRateModal);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                if(error.response.data.message == 'min_must_greater_than_max_other_records') {
                                    error.response.data.message = this.$t('pages.ecommerce.shippingMethod.rate.responseMessages.minGreaterMaxOtherRecords');
                                }

                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/shippingMethod/rate/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if (successDeleted) {
                    this.loadPaymentMethod();
                }
            });
        },
        prepareRateFormData(){
            let formData = this.form.data;

            formData.min = parseFloat(formData.min);
            formData.max = parseFloat(formData.max);

            if(formData.min >= formData.max){
                this.$notify({
                    type: 'error',
                    title: this.$t('messages.error'),
                    message: this.$t('pages.ecommerce.shippingMethod.rate.responseMessages.minGreaterMax')
                });
                return false;
            }

            return true;
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRate();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>